/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
    mobileMenu: function() {
      $height = $("body").height();
      $("nav").css("height", $height);
    }
  };

  // Load Events
  $(document).ready(function () {
    UTIL.loadEvents(); 
    UTIL.mobileMenu();
  });

  window.onresize = function(event) {
    UTIL.mobileMenu();
  };

  // Mobile menu 
  $('header .menu-open-button').on('click', function() {
    $('nav').addClass('opened');
  });

  $('nav .menu-close-button').on('click', function() {
    $('nav').removeClass('opened');
  });

  // Mobile submenu
  $('.menu-item-has-children').on('click', function() {
    if ($(this).hasClass('opened')) {
      $(this).removeClass('opened');
      $(this).children('.sub-menu').slideUp('fast');
    } else {
      $(this).addClass('opened');
      $(this).children('.sub-menu').slideDown('slow');
    }
  });

  // Footer link on mobile
  $('.back-to-top').on('click', function() {
    $('html, body').animate({scrollTop: $("#header").offset().top}, 500);
  });

  // Tabs  
  // Javascript to enable link to tab
  var url = document.location.toString();
  if (url.match('#')) {
    $('.nav-tabs a[href=#'+url.split('#')[1]+']').tab('show');
  }

  $('nav .sub-menu li a').on('click', function() {
    var url = $(this).attr('href');
    if (url.match('#')) {
      $('.nav-tabs a[href=#'+url.split('#')[1]+']').tab('show');
      $('nav').removeClass('opened');
    }
  });

  if (url.match('#')) {
    $('.region-tabs a[href=#'+url.split('#')[1]+']').tab('show');
  }

  // FAQ
  $('#faq-accordion h4').on('click', function() {
    if (jQuery(this).hasClass('opened')) {
        jQuery(this).removeClass('opened');
        jQuery(this).next('div').slideUp('fast');
    } else {
        jQuery(this).parent().find('h4').removeClass('opened');
        jQuery(this).parent().find('h4').next('div').slideUp('fast');
        jQuery(this).addClass('opened');
        jQuery(this).next('div').slideDown('fast'); 
    }
  });

  // Documents
  $('#documents h4').on('click', function() {
    if (jQuery(this).hasClass('opened')) {
        jQuery(this).removeClass('opened');
        jQuery(this).next('div').slideUp('fast');
    } else {
        jQuery(this).addClass('opened');
        jQuery(this).next('div').slideDown('fast'); 
    }
  });


    function showTarget() {
        var hash = window.location.hash;
        if (!hash){
            return;
        }

        var target = document.getElementById(hash.slice(1));
        if (!target){
            return;
        }

        $('a, h4').removeClass('highlight');
        $(target).addClass('highlight');

        var parents = $(target).parents('.collapse').toArray().reverse();

        function next(parent) {
            var $parent = $(parent);
            $parent.prev('h4').addClass('opened');
            $parent.show('fast', function(){
                if (parents.length) {
                    setTimeout(function(){
                        next(parents.shift());
                    },300);
                    return;
                }else{
                    $(target).addClass('opened');
                    $(target).next('.collapse').show('fast');
                }

                //$('html, body').animate({
                //    scrollTop: $(target).offset().top-100
                //}, 1000);

            });
        }
        setTimeout(function(){
            next(parents.shift());
        },300);
    }

    if($('body.public-documents').length){
        window.addEventListener('popstate', showTarget);
        showTarget();
    }

  // World map
  $('#region_info').on('click', '.close-country-info', function() {
    $('#region_info').fadeOut('fast');
    $('.layer-fade').fadeOut('fast');
  });

  // Bottom flag carousel
  var hover = false;
  $(function () {

    var startTime = +new Date();
    
    var slider = $('#slider'),
        items = slider.find('li');
    var sliderWidth = slider.width(),
        itemsNum = items.length,
        itemWidth = items.width();

    slider.css('left', '0');
    var startPos = slider.css('left');

    items.first().before(items.clone());
    items.last().after(items.clone());

    scrollItems(slider,sliderWidth,itemWidth,startPos,60000);
    var endTime = +new Date();
    var diff = endTime - startTime;
    
    setInterval(function(){ 
        scrollItems(slider,sliderWidth,itemWidth,startPos,60000);
    }, 10000);

    $("#bottom-section").hover(function() {
      hover = true;
      $("#slider").stop(true);
    }, function() {
      hover = false;
      scrollItems(slider,sliderWidth,itemWidth,startPos,60000);
    });

  });

  function scrollItems(container, targetsWidth, increment, startPos, temps) {
    if (hover) { return; }
    container.animate({
        'left': '-' + (targetsWidth + increment) + 'px'
    }, temps, 'linear', function () {
        container.css('left', startPos);
    });
  }

  // Home top images - fade
  function cycleImages() {
    $('.top-img-container').each(function(elm) {
      var active = $(this).children('.active');
      var next = (active.next().length > 0) ? active.next() : $(this).children('img:first');
      next.css('z-index',2); //move the next image up the pile
      active.fadeOut(2000,function(){  //fade out the top image
        active.css('z-index',1).show().removeClass('active');  //reset the z-index and unhide the image
        next.css('z-index',3).addClass('active');  //make the next image the top one
      });
    });
  }

  setInterval(function(){ 
    cycleImages();
  }, 7000);

  $("ul.region-list li a").on("click", function(){
    var countryId = $(this).data("countryId");
    $('.region-info').hide();
    $('#region-info-'+countryId).show();

    var href = $(this).attr("href");
    $(".nav-tabs-box a[href="+href+"]").tab("show");

    $("ul.region-list li").removeClass("active");
    $("#region-list-contact-"+countryId).addClass("active");
    $("#region-list-document-"+countryId).addClass("active");

    return false;
  });

  // Languge switcher
  jQuery('#menu-language-switcher .menu-item').on("click", function(){
    jQuery(this).toggleClass('active');
  });

})(jQuery); // Fully reference jQuery after this point.